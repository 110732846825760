<template lang="pug">
ContainerForData(width="100%" :is-loading="isLoading")
    template(#header-right)
        .flex.items-center
            .flex.items-center
                button.date-button(
                    :disabled="datesFrom <= 0"
                    @click="changeShowedDates(-1)"
                ) &#x2039; Earler dates

                button.date-button(
                    :disabled="datesTo >= tableDates.length"
                    @click="changeShowedDates(1)"
                ) Later dates &#x203A;

            //- Search(v-model="searchValue" @change="searchItems")

    template(#data)
        .table-content
            Table.mb-12(v-if="totalCount" v-bind="tableOptions")
                template(#brand="{item, value}")
                    .link-wrapper
                        RouterLink(:to="{ name: 'Nestle.BrandsAndSKU.SKU', params: { id: item.brandId } }")
                            | {{ value }}

            span(v-else) No data.
            
            Pagination(
                :total="totalCount"
                :current="currentPage"
                :limit="limit"
                @change-page="currentPage = $event"
            )
</template>

<script>
import { mapMutations } from 'vuex'
import ContainerForData from '@/components/Nestle/ContainerForData.vue';
import Search from '@/components/Nestle/Search.vue';
import Table from '@/components/Table/Table.vue';
import Pagination from "@/components/Pagination/Pagination.vue"

export default {
    components: {
        ContainerForData,
        Search,
        Table,
        Pagination,
    },

    props: {
        requestParams: {
            type: Object,
            required: true,
        },

        needUpdate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: false,
            items: [],
            searchValue: '',

            limit: 20,
            totalCount: 0,
            currentPage: 1,

            maxShowedDates: 14,
            datesFrom: 0,
            datesTo: 4,

            sortBy: "brandName",
            sortOrder: "ASC",
        }
    },

    computed: {
        tableRequestParams() {
            return {
                ...this.requestParams,
                limit: this.limit,
                offset: this.limit * (this.currentPage - 1),
                sortBy: this.sortBy,
                sortOrder: this.sortOrder,
                searchQuery: this.searchValue,
            }
        },

        tableDates() {
            const item = this.items[0];
            if (!item) return [];

            return item.shareForPeriod.map((period) => {
                return {
                    title: this.$utils.format(this.$utils.parseISO(period.observedAt), 'MMM dd'),
                    width: 30,
                    value: (item) => this.findShareOfPromoValue(period.observedAt, item),
                    alignRight: true,
                }
            })
        },

        tableColumns() {
            return [
                {
                    title: 'Бренд',
                    width: 70,
                    slot: 'brand',
                    value: (item) => item.brandName,
                },
                ...this.tableDates.slice(this.datesFrom, this.datesTo),
            ]
        },

        tableOptions() {
            return {
                columns: this.tableColumns,
                data: this.items,
            }
        },
    },

    methods: {
				...mapMutations('brandAndSku', [
          'setRequestParams',
        ]),
        async searchItems() {
            this.currentPage = 1;
            await this.fetchData();
        },

        findShareOfPromoValue(date, item) {
            const currentPeriod = item.shareForPeriod.find((period) => period.observedAt === date);
            const value = currentPeriod?.sharePromo || 0;

            return value + '%';
        },

        setDatesPaginationParams() {
            const item = this.items[0];
            if (!item) return;

            const datesCount = item.shareForPeriod?.length || 1;
            this.datesTo = datesCount;

            const datesFrom = this.datesTo - this.maxShowedDates;
            this.datesFrom = datesFrom <= 0 ? 0 : datesFrom;
        },

        changeShowedDates(direction) {
            this.datesFrom += direction;
            this.datesTo += direction;
        },

        async fetchData() {
            this.isLoading = true;
            this.setRequestParams(this.tableRequestParams)

            try {
                const response = await this.$api.common.getBrandPromoPeriod(this.tableRequestParams);
                const isResponseValid = response && Array.isArray(response.list);

                this.items = isResponseValid ? response.list : [];
                this.totalCount = response.count || 0;
            } catch {
                this.items = [];
                this.totalCount = 0;

            }

            this.setDatesPaginationParams();
            this.isLoading = false;
        }
    },

    watch: {
        async currentPage() {
            await this.fetchData();
        },

        async needUpdate() {
            this.currentPage = 1;
            await this.fetchData();
        }
    },

    async mounted() {
        await this.fetchData();
    }
}
</script>

<style scoped lang="scss">
.table-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    overflow-x: hidden;
}

::v-deep .td__cell {
    word-break: normal;
}

.date-button{
	font-size: 14px;
	background-color: #fff;
	outline: 1px color(gray-400) solid;
	padding: 0 5px;
	border-radius: 3px;
	margin-top: 1px;
	color: #303030;
	&:disabled {
		cursor: not-allowed;
		color: color(gray-600);
	}
}
</style>
