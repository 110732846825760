<template lang="pug">
ContainerForData(width="100%" :is-loading="isLoading")
    template(#header-right)
        .flex.items-center
            //- Search(v-model="searchValue" @change="searchItems")
    template(#data)
        .table-content
            Table.mb-12(v-if="totalCount" v-bind="tableOptions")
                template(#brand="{item, value}")
                    .link-wrapper
                      a(@click="getSKU(item)")
                        | {{ value }}
            
            span(v-else) No data.

            Pagination(
                :total="totalCount"
                :current="currentPage"
                :limit="limit"
                @change-page="currentPage = $event"
            )
</template>

<script>
import { mapMutations } from 'vuex'
import ContainerForData from '@/components/Nestle/ContainerForData.vue';
import Search from '@/components/Nestle/Search.vue';
import Table from '@/components/Table/Table.vue';
import Pagination from "@/components/Pagination/Pagination.vue"

export default {
    components: {
        ContainerForData,
        Search,
        Table,
        Pagination,
    },

    props: {
        requestParams: {
            type: Object,
            required: true,
        },
        needUpdate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: false,
            items: [],
            searchValue: '',

            limit: 20,
            totalCount: 0,
            currentPage: 1,

            sortBy: "brandName",
            sortOrder: "ASC",
        };
    },

    computed: {
        tableRequestParams() {
            return {
                ...this.requestParams,
                limit: this.limit,
                offset: this.limit * (this.currentPage - 1),
                sortBy: this.sortBy,
                sortOrder: this.sortOrder,
                searchQuery: this.searchValue,
            }
        },

        tableColumns() {
            return [
                {
          title: 'Бренд',
          width: 110,
          slot: 'brand',
          value: (item) => item.brandName,
        },
                {
          title: 'Доля полки',
          width: 110,
          value: (item) => item.shareOfShelf,
                    alignRight: true,
        },
                {
          title: 'Сред. SKU доступно в день',
          width: 165,
          value: (item) => item.avgSkuAvailable,
                    alignRight: true,
        },
                {
          title: 'Кол-во SKU',
          width: 110,
          value: (item) => item.countSku,
                    alignRight: true,
        },
                {
          title: 'Доля промо',
          width: 110,
          value: (item) => item.shareOfPromo,
                    alignRight: true,
        },
                {
          title: 'Сред. скидка',
          width: 110,
          value: (item) => item.averageDiscount,
                    alignRight: true,
        },
                {
          title: 'Макс. скидка',
          width: 110,
          value: (item) => item.maxDiscount,
                    alignRight: true,
        },
                {
          title: 'Сред. цена SKU',
          width: 110,
          value: (item) => item.averagePrice,
                    alignRight: true,
        },
                {
          title: 'Сред. цена за гр.',
          width: 120,
          value: (item) => item.avgPricePerUnitOfWeight,
                    alignRight: true,
        },
            ]
        },

        tableOptions() {
      return {
        columns: this.tableColumns,
        data: this.items,
      }
    },
    },

    methods: {
        ...mapMutations('brandAndSku', [
          'setRequestParams',
          'setBrandId'
        ]),
        getSKU(item) {
          this.setBrandId(item.brandId);
          this.$router.push({ name: 'Nestle.BrandsAndSKU.SKU', params: { id: item.brandId }});
        },
        async searchItems() {
            this.currentPage = 1;
            await this.fetchData();
        },

        async fetchData() {
            this.isLoading = true;
            this.setRequestParams(this.tableRequestParams)

            try {
                const response = await this.$api.common.getBrandsTableData(this.tableRequestParams);
                const isListValid = response && Array.isArray(response.list);

                this.items = isListValid ? response.list : [];
                this.totalCount = response?.count || 0;
            } catch {
                this.items = [];
                this.totalCount = 0;
            }

            this.isLoading = false;
        }
    },

    watch: {
        async currentPage() {
            await this.fetchData();
        },

        async needUpdate() {
            this.currentPage = 1;
            await this.fetchData();
        }
    },

    async mounted() {
        await this.fetchData();
    }
}
</script>

<style scoped lang="scss">
.table-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    overflow-x: hidden;
}

::v-deep .td__cell {
    word-break: normal;
}
</style>
